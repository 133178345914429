@use '../../styles/partials/mixins';

.article_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;

    &--header {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    &--title {
        color: #363636;
        margin-bottom: 8px;
    }

    &--sub_title {
        font-size: 18px;
        color: #777;
    }

    &--empty {
        color: #F44336;
        margin: 24px 0;
    }
}

.article_item {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;


    &-cover_image {
        width: 64px;
    }

    &-context {
        display: flex;
        gap: 8px;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    &-title {
        font-size: larger;
        text-decoration: none;
    }
}

@include mixins.tablet {
    .article_list {
        padding: 24px 8%;
    }
}

@include mixins.desktop {
    .article_list {
        padding: 24px 15%;
    }
}