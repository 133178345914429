@use '../../styles/partials/mixins';

.form_page {
    margin: 24px 0;

    &-content {
        width: 80%;
        margin: auto;
    }

    &-title {
        color: #777;
    }
}


@include mixins.tablet {
    .form_page {
        margin: 42px 0;

        &-content {
            width: auto;
            margin: 0 20%;
        }
    }
}