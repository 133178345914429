.message_box {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 300px;
    max-width: 50%;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 8px;
    animation: slide_up 0.6s;
    z-index: 1000;
    box-shadow: -1px 2px 6px 2px rgba($color: #000000, $alpha: 0.3);
    font-weight: 500;

    &--error {
        color: white;
        background-color: red;
    }

    &--success {
        color: white;
        background-color: green;
    }

    &--warn {
        color: white;
        background-color: rgb(255, 149, 0);
    }
}

@keyframes slide_up {
    from {
        opacity: 0;
        transform: translateY(30%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}