$mobile-breakpoint: 767px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

@mixin mobile {
    @media(max-width : $mobile-breakpoint) {
        @content;
    }
}

@mixin tablet {
    @media(min-width : $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media(min-width : $desktop-breakpoint) {
        @content;
    }
}